import { fetchHubspotDealByProperties } from '@utils/hubspot'
import { IHubspotDeal } from 'types/hubspot'
import { COOKIE_HUBSPOT } from '@utils/constants'
import Cookie from 'js-cookie'

import { hubspotDealToPlusOrder } from 'app/_services/CRM/hubspot/parsers/dealToQuoteOrder'
import { IPlusOrder } from 'app/_services/CRM/hubspot/types'
import {
  DRAFT_ORDER_DEAL_STAGES,
  HUBSPOT_DEAL_STAGES,
} from 'app/_services/CRM/hubspot/constants'

// Quote related
export const orderToQuotePayload = (
  order: IPlusOrder,
  lineItems,
  extraDetails
) => {
  const orderLineItemsToPayload = (lineItems) => {
    return lineItems?.map((item) => {
      const optionsFields = item.options
        ?.map((option) => {
          if (option?.key?.toLowerCase() !== 'quantity') {
            return {
              label: option?.key,
              value: option?.value,
              name: option?.key?.toLowerCase()?.replaceAll(' ', '_'),
            }
          }
          return null
        })
        .filter((option) => (option?.label || option?.name) && option?.value)

      return {
        fields: [
          ...optionsFields,
          {
            name: 'image_url',
            value: lineItems?.[0]?.image,
            label: 'Image URL',
          },
          {
            name: 'page_url',
            value: lineItems?.[0]?.url,
            label: 'Page URL',
          },
          {
            name: 'sku',
            value: lineItems?.[0]?.sku,
            label: 'SKU',
          },
          {
            name: 'product_name',
            value: lineItems[0]?.name,
            label: 'Product Name',
          },
          {
            name: 'product_slug',
            value: lineItems[0]?.name?.toLowerCase()?.replaceAll(' ', '-'),
            label: 'Product Slug',
          },
          {
            name: 'quantity',
            value: String(lineItems[0]?.quantity),
            label: 'Quantity',
          },
        ],
      }
    })
  }

  return {
    fields: [
      {
        name: 'line_items',
        label: 'Line Items',
        value: orderLineItemsToPayload(lineItems),
      },
      {
        name: 'parent_deal_id',
        value: order?.id,
        label: 'Parent Deal Id',
      },
      {
        name: 'extra_details',
        value: extraDetails,
        label: 'Extra details',
      },
    ],
    context: {
      hutk: Cookie.get(COOKIE_HUBSPOT),
    },
  }
}

const HUBSPOT_LEGACY_PIPELINE = '24804935'

export const getPlusOrders = async ({
  hubspotContact,
  ordersPerPage,
  page,
}: {
  hubspotContact: any
  ordersPerPage: number
  page: number
}): Promise<IPlusOrder[]> => {
  const hubspotDeals: IHubspotDeal[] =
    (
      await fetchHubspotDealByProperties({
        propertyFilters: [
          {
            propertyName: 'dealstage',
            operator: 'IN',
            values: Object.values(HUBSPOT_DEAL_STAGES)?.flat(),
          },
          {
            propertyName: 'associations.contact',
            operator: 'EQ',
            value: hubspotContact?.id,
          },
          {
            propertyName: 'pipeline',
            operator: 'NEQ',
            value: HUBSPOT_LEGACY_PIPELINE,
          },
        ],
        limit: ordersPerPage,
        after: (page - 1) * ordersPerPage,
      })
    ).results || []

  return (
    hubspotDeals
      .map((deal) =>
        hubspotDealToPlusOrder({
          deal,
        })
      )
      // No need to display plus deal if it has a corresponding BC order ID
      .filter((x) => x && !x.bcOrderId)
  )
}

export const getDraftPlusOrders = async ({
  hubspotContact,
  ordersPerPage,
  page,
  sorts = [],
}: {
  hubspotContact: any
  ordersPerPage: number
  page: number
  sorts?: Array<any>
}): Promise<IPlusOrder[]> => {
  const hubspotDeals: IHubspotDeal[] =
    (
      await fetchHubspotDealByProperties({
        propertyFilters: [
          {
            propertyName: 'dealstage',
            operator: 'IN',
            values: DRAFT_ORDER_DEAL_STAGES,
          },
          {
            propertyName: 'associations.contact',
            operator: 'EQ',
            value: hubspotContact?.id,
          },
          {
            propertyName: 'pipeline',
            operator: 'NEQ',
            value: HUBSPOT_LEGACY_PIPELINE,
          },
        ],
        limit: ordersPerPage,
        after: (page - 1) * ordersPerPage,
        sorts,
      })
    ).results || []

  return hubspotDeals
    .map((deal) =>
      hubspotDealToPlusOrder({
        deal,
      })
    )
    .filter((x) => x && !x.bcOrderId)
}
