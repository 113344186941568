import { getLineItemsBelongToSaleOrders } from '@components/lib/getLineItemsBelongToSaleOrders'
import { invalidOrderStatuses } from '@utils/orderStatuses'
import { getPrice } from '@utils/utilities'
import { Customer } from '@framework/api/customers'
import { ISOLinkedPurchaseOrder, ISalesOrder } from 'types/zendesk/salesOrder'
import { TOrderLifeCycleStatus } from 'app/_services/Commerce/types/orderStatus'
import {
  IRawDTCOrder,
  IDTCOrder,
  IDTCOrderBundle,
} from 'app/(main)/admin/orders/[orderId]/_types'
import { TSalesOrderStatus, TOrderStatus } from 'app/_types/orders'

export enum SalesOrderStatus {
  IN_DESIGN = 'in_design',
  APPROVED = 'design_approved',
  SENT_TO_PRINT = 'sent_to_print',
  READY_TO_SHIP = 'ready_to_ship',
  SHIPPED = 'order_shipped',
  DELIVERED = 'delivered',
  CANCELLED = 'order_cancelled',
}

export enum BigCommerceProductType {
  CUSTOM = 'custom',
  STOCK = 'stocked',
}

export async function getOrders(page: number, limit = 10) {
  const result = await fetch(
    `/api/bigcommerce/orders/?limit=${limit}&page=${page}`
  )

  try {
    const res = await result.json()
    return res
  } catch {
    return { orders: [] }
  }
}

const getMagentoOrders = async () => {
  try {
    return (await fetch(`/api/magento/orders/`)).json()
  } catch {
    return { orders: [] }
  }
}

const getMagentoProducts = async (orderId: string) => {
  try {
    return (await fetch(`/api/magento/orders/${orderId}/products/`)).json()
  } catch {
    return { products: [] }
  }
}

export function isMagentoOrder(orderId: number) {
  // BigCommerce orders started at 700000000 in Production, and 900000000 in Test BC.
  // Any orders below 700000000 are from the legacy Magento System
  return orderId < 700000000
}

export const mapBcOrderStatusToOrderLifecycleStatus: Record<
  string,
  TOrderLifeCycleStatus
> = {
  'awaiting payment': 'awaiting payment',
  pending: 'pending',
  'awaitintg fulfillment': 'pending',
  'manual verification required': 'pending',
  'awaiting fulfillment': 'pending',
  'awaiting shipment': 'production',
  'awaiting pickup': 'shipped',
  'partially shipped': 'shipped',
  shipped: 'shipped',
  completed: 'complete',
  'partially refunded': 'complete',
  cancelled: 'cancelled',
  declined: 'cancelled',
  refunded: 'cancelled',
  disputed: 'cancelled',
}

export const parseBCOrder = (bcOrder: IRawDTCOrder): IDTCOrder => {
  return {
    id: String(bcOrder?.id),
    orderPageSlug: `/admin/orders/${String(bcOrder?.id)}`,
    timestamp: new Date(bcOrder?.date_created).getTime(),
    type: bcOrder.isQuote ? 'Quote' : 'DTC',
    xeroInvoiceId: bcOrder?.xeroInvoiceId,
    hubspotDealId: bcOrder?.hubspotDealId,
    shipTo: bcOrder?.deliveryCustomer,
    createdDate: new Date(bcOrder?.date_created).toLocaleString('en', {
      dateStyle: 'medium',
    }),
    price: bcOrder?.total_inc_tax,
    currency: bcOrder?.currency_code,
    totalItems: bcOrder?.items_total || bcOrder?.items,
    archived: bcOrder?.is_deleted,
    titleImage: bcOrder?.firstProductImageUrl || '/plus-order-icon.png',
    status:
      (mapBcOrderStatusToOrderLifecycleStatus[
        bcOrder?.status?.toLowerCase()
      ] as TOrderLifeCycleStatus) || 'pending',
    products: bcOrder?.products,
  }
}

const statusText: Record<TSalesOrderStatus, TOrderStatus> = {
  in_design: 'in design',
  design_approved: 'approved',
  sent_to_print: 'approved',
  ready_to_ship: 'approved',
  order_shipped: "on it's way",
  delivered: 'delivered',
  order_cancelled: 'cancelled',
}

const statusValues: Record<TSalesOrderStatus, number> = {
  in_design: 0,
  design_approved: 1,
  sent_to_print: 1,
  ready_to_ship: 2,
  order_shipped: 2,
  delivered: 3,
  order_cancelled: 4,
}

export const mapRawBundleStatusToOrderStatus = (
  bundleStatus: TOrderStatus
): TOrderLifeCycleStatus => {
  switch (bundleStatus) {
    case 'in design':
    case 'active':
      return 'pending'

    case 'approved':
      return 'production'

    case 'completed':
    case 'delivered':
      return 'complete'

    case `on it's way`:
      return 'shipped'

    case 'cancelled':
      return 'cancelled'

    default:
      return 'pending'
  }
}

export const findProductsPurchaseOrder = (
  salesOrders: ISalesOrder[] = [],
  product: any
): ISOLinkedPurchaseOrder => {
  const purchasedProductId = product.id
  if (!salesOrders.length) {
    return null
  }

  const allPurchaseOrders =
    salesOrders.map((x) => x.linkedPurchaseOrders || []).flat() || []

  if (!allPurchaseOrders.length) {
    return null
  }

  let productsPurchaseOrder =
    allPurchaseOrders.find((x) => {
      return +x.id === +purchasedProductId
    }) || null

  if (!productsPurchaseOrder) {
    // If purchasedProductId didn't match any purchaseOrder.id it means that the initial order is reorder itself.
    // in this case we need to compare product id from customization object:
    const customizationOption = product.product_options?.find(
      (x) => x.display_name.toLowerCase() === 'customizations'
    )
    const customizationOptionValue = JSON.parse(
      customizationOption?.display_value || '{}'
    )
    const reorderedProductId = customizationOptionValue?.id

    productsPurchaseOrder =
      allPurchaseOrders.find((x) => {
        return +x.id === +reorderedProductId
      }) || null
  }

  return productsPurchaseOrder
}

function getLegacyDesignFileLink(salesOrder, item) {
  return salesOrder.linkedPurchaseOrders[0] &&
    item.id === salesOrder.linkedPurchaseOrders[0].id
    ? salesOrder.linkedPurchaseOrders[0].filesUrls?.[0]?.googleDriveViewLink
    : ''
}

export function getDesignFileLink(salesOrder, item) {
  if (Object.keys(salesOrder.linkedPurchaseOrders).length === 0) {
    return null
  }

  const purchasedItem = salesOrder?.linkedPurchaseOrders?.find(
    (x) => x.id === item?.id
  )
  if (purchasedItem) {
    const validFileUrls = purchasedItem?.filesUrls
      ?.map((fileUrl) => {
        if (fileUrl.googleDriveFileName.indexOf('production') !== -1) {
          return fileUrl.googleDriveViewLink
        } else {
          return item.customerPreviewLinks?.[0]
        }
      })
      .filter(Boolean)

    return validFileUrls?.[0] || null
  }
  return null
}

export const getOrderBundleFromMagentoOrder = (
  order: IDTCOrder,
  lineItems: any[]
): IDTCOrderBundle => {
  return {
    id: order.id,
    status: statusText.delivered,
    carrierName: '',
    tracking: '',
    trackingUrl: '',
    arrival: 'Arrived',
    lineItems: lineItems?.map((item) => ({
      id: item.id,
      orderType: 'DTC',
      type: item.type === 'stocked' ? 'Stock' : 'Custom',
      name: item.display,
      image: '/plus-order-icon.png',
      designFileLink: item.previewLink,
      legacyDesignFileLink: item.previewLink,
      designWasApproved: item.type === 'custom',
      options: [
        { key: 'size', value: item.size },
        { key: 'quantity', value: item.quantity },
        { key: 'thickness', value: item.thickness },
        {
          key: 'colors',
          value: item.logoColor && item.paperColor ? '2 Colors' : '1 Color',
        },
      ],
      price: order.currency + ' ' + getPrice(item.unitPrice),
    })),
  }
}

export const getOrderBundlesFromSalesOrders = (
  salesOrders,
  order: IDTCOrder,
  productData
): IDTCOrderBundle[] => {
  return salesOrders?.map((salesOrder) => {
    const shipmentInfo = salesOrder.shipments?.[0] || null

    return {
      id: salesOrder.salesOrderId,
      status: isMagentoOrder(+order?.id)
        ? statusText.delivered
        : statusText[salesOrder.status],
      carrierName: shipmentInfo?.carrier || 'Not available yet',
      tracking: shipmentInfo?.trackingNumber || 'Not available yet',
      trackingUrl: shipmentInfo?.trackingUrl || '',
      arrival:
        shipmentInfo?.estimatedDeliveryDate ||
        salesOrder.estimatedDeliveryDate ||
        'Not available yet',
      lineItems: getLineItemsBelongToSaleOrders(salesOrder).map((product) => {
        const designWasApproved =
          statusValues[salesOrder.status] >= statusValues.design_approved &&
          product.type !== 'stocked'

        const productImage =
          productData.find(({ data }) => {
            return product.name
              ?.toLowerCase()
              ?.includes(data?.name?.toLowerCase())
          })?.data?.images?.[0]?.url_thumbnail ?? ''

        return {
          id: product.id,
          zendeskTicketId: salesOrder?.zendeskTicketId,
          salesOrderReference: salesOrder?.reference,
          purchaseOrderRef: product?.purchaseOrderRef,
          orderType: 'DTC',
          type: product.type === 'stocked' ? 'Stock' : 'Custom',
          isCustomizable: product.type !== 'stocked',
          name: product.name?.split(' | ')?.[0] ?? '',
          image: productImage,
          designFileLink:
            getDesignFileLink(salesOrder, product) ||
            getLegacyDesignFileLink(salesOrder, product),
          designWasApproved: designWasApproved,
          designJourney: product?.designJourney,
          fileUrls: product?.filesUrls,
          options: [
            {
              key: 'size',
              value:
                product.size ||
                product.options?.find((option) => option.Size)?.Size,
            },
            {
              key: 'type',
              value:
                product.baseColor ||
                product.options?.find((option) => option.Type)?.Type,
            },
            {
              key: 'shape',
              value:
                product.stickershape ||
                product.options?.find((option) => option.Shape)?.Shape,
            },
            { key: 'quantity', value: product.packSize },
            { key: 'thickness', value: product.thickness },
            {
              key: 'colors',
              value: product.options?.find((option) => option.Colors)?.Colors,
            },
          ],
          price: order.currency + ' ' + getPrice(product.unitPrice),
        }
      }),
    }
  })
}
