import { useEffect, useRef } from 'react'
import { CgMenuLeftAlt } from 'react-icons/cg'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import cn from 'classnames'
import { Logo } from '@components/ui'
import { CartButton } from '../CartButton'
import { useIsOutsideDOMElement } from '@utils/hooks/useIsOutsideDOMElement'
import { TLink } from '../Navbar.types'
import { MobilePrimaryMenu } from './MobilePrimaryMenu'
import { MobileSecondaryMenu } from './MobileSecondaryMenu'
import { MobileFooter } from './MobileFooter'
import { MobileSelectionMenu } from './MobileSelectionMenu'
import { LoginSignup } from '@components/common/UserNav/LoginSignup'
import { useSelectedSecondaryMenu } from '../NavbarDataProvider'
import { useRouter } from 'next/router'
import { TDropdownTile } from '../SecondaryMenu/TileSection/TileSection.types'
import { MobileTiles } from './MobileTiles'
import { SearchButton } from '../SearchButton'
import { Button } from '@noissue-ui-kit/button'
import { AccountPlain } from 'app/(main)/_components/UserNav/AccountPlain'

export const MobileNavbar = ({
  mobileFooterLinks,
  navbarMobileTiles,
  announcementBannerLinks,
}: {
  mobileFooterLinks: TLink[]
  navbarMobileTiles: TDropdownTile[]
  announcementBannerLinks: { text: string; link: string }[]
}) => {
  const { data: session } = useSession()
  const { setSelectedSecondaryMenuIndex } = useSelectedSecondaryMenu()
  const router = useRouter()
  const mobileNavbarRef = useRef(null)
  const maskRef = useRef(null)
  const isOutside = useIsOutsideDOMElement(
    mobileNavbarRef,
    (element: HTMLElement) => element.getBoundingClientRect().x === 0
  )

  const handleMenuToggle = () => {
    if (mobileNavbarRef.current) {
      mobileNavbarRef.current.classList.toggle('!translate-x-0')
    }
    if (maskRef.current) {
      maskRef.current.classList.toggle('opacity-40')
      maskRef.current.classList.toggle('h-full')
    }
    document.body.classList.toggle('!overflow-hidden')
    setSelectedSecondaryMenuIndex(-1)
  }

  useEffect(() => {
    if (isOutside) {
      handleMenuToggle()
    }
  }, [isOutside])

  return (
    <>
      <div className="bg-core-white h-[65px] top-0 w-full flex flex-row items-center justify-between px-4 py-6 font-mori">
        <div>
          <CgMenuLeftAlt
            className="h-[30px] w-[30px] text-boulder cursor-pointer"
            onClick={handleMenuToggle}
          />
          <nav role="navigation">
            <div
              ref={maskRef}
              className="fixed top-0 left-0 w-full max-h-[100vh] bg-core-black opacity-0 transition-opacity duration-300 z-1"
            ></div>
            <div
              className="fixed top-0 left-0 w-[89%] max-w-[440px] h-[100vh] max-h-[100vh] bg-core-white rounded-tr-[30px] rounded-br-[30px] overflow-y-scroll overflow-x-hidden -translate-x-full transition-all duration-300 no-scrollbar scroll-smooth z-20"
              ref={mobileNavbarRef}
            >
              <div className="relative">
                <div
                  className={`${cn(
                    'flex flex-col items-start gap-[24px] px-[24px]',
                    session ? 'min-h-[61vh]' : 'min-h-[84vh]'
                  )}`}
                >
                  <MobilePrimaryMenu handleMenuToggle={handleMenuToggle} />
                  <MobileSecondaryMenu handleMenuToggle={handleMenuToggle} />
                  <MobileSelectionMenu handleMenuToggle={handleMenuToggle} />
                  {!session && (
                    <div className="w-full" onClick={handleMenuToggle}>
                      <LoginSignup size="m" className="w-full mt-4" />
                    </div>
                  )}

                  {session && (
                    <Button
                      variant="primary"
                      colour="acai"
                      size="m"
                      corners="rounded"
                      onClick={async () => {
                        handleMenuToggle()
                      }}
                      className="w-full"
                      href="/admin/orders"
                      Component={'a'}
                    >
                      My Orders
                    </Button>
                  )}
                  <MobileTiles tiles={navbarMobileTiles} className="mt-2" />
                </div>
                <div className="flex flex-col items-start gap-[32px] px-[24px] pt-[32px] pb-[120px] bg-core-grey-10">
                  <div onClick={handleMenuToggle}>
                    <AccountPlain
                      announcementBannerLinks={announcementBannerLinks}
                    />
                  </div>

                  <div className="border w-[90%] border-core-purple-10"></div>

                  <div onClick={handleMenuToggle}>
                    <MobileFooter mobileFooterLinks={mobileFooterLinks} />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <Logo className="pb-2 mb-0" />

        <CartButton />
      </div>
      <div className="flex w-full pb-5">
        <SearchButton />
      </div>
    </>
  )
}
