import { isoDateToReadableDate } from '@utils/utilities'

import { IHubspotDeal, IPlusOrder, TDealStage } from '../types'
import { DRAFT_ORDER_DEAL_STAGES, HUBSPOT_DEAL_STAGES } from '../constants'
import { TPlusOrderStatus } from 'app/(main)/admin/orders/[orderId]/_types'

const mapDealStateToPlusOrderStatus = (dealStage): TPlusOrderStatus => {
  let orderStatus: TPlusOrderStatus = null

  if (DRAFT_ORDER_DEAL_STAGES.includes(dealStage)) {
    return 'awaiting payment'
  }

  for (const [key, value] of Object.entries(HUBSPOT_DEAL_STAGES)) {
    if (value.includes(dealStage)) {
      orderStatus = key as TPlusOrderStatus
    }
  }

  return orderStatus
}

const hubspotDealToPlusOrder = ({
  deal,
}: {
  deal: IHubspotDeal
}): IPlusOrder => {
  const orderType = DRAFT_ORDER_DEAL_STAGES.includes(
    deal?.properties?.dealstage as TDealStage
  )
    ? 'Quote'
    : 'Plus'
  return {
    id: deal?.id,
    dealId: deal?.id,
    orderPageSlug:
      orderType === 'Quote'
        ? `/admin/orders/quote/${deal?.id}`
        : `/admin/orders/plus/${deal?.id}`,
    timestamp: new Date(
      deal?.properties?.closedate || deal?.properties?.createdate
    ).getTime(),
    type: orderType,
    bypassUOP: deal?.properties?.bypass_uop === 'yes',
    shipTo: '',
    createdDate: isoDateToReadableDate(
      deal?.properties?.closedate || deal?.properties?.createdate
    ),
    price: deal?.properties?.amount,
    currency: deal?.properties?.deal_currency_code,
    totalItems: +deal?.properties?.hs_num_of_associated_line_items,
    archived: deal?.archived,
    titleImage: '/plus-order-icon.png',
    status: mapDealStateToPlusOrderStatus(deal?.properties?.dealstage),
    cin7SO: deal?.properties?.cin7_so,
    lineItems: [],
    xeroInvoiceId: deal?.properties?.xero_invoice_number,
    carrier: deal?.properties?.carrier,
    trackingNumber: deal.properties?.tracking_number,
    estimatedArrival:
      deal?.properties?.estimated_arrival || 'Not available yet',

    shippingAddress: {
      firstName: deal?.properties?.shipping_first_name,
      lastName: deal?.properties?.shipping_last_name,
      company: '',
      streetInfo: deal.properties.shipping_street_address,
      additionalStreetInfo: '',
      city: deal.properties.shipping_city,
      zip: deal.properties.shipping_postal_code,
      country: deal.properties.shipping_country,
      state: deal.properties.shipping_state_region,
      email: deal?.properties?.shipping_email,
      phone: deal?.properties?.shipping_phone,
    },

    billingAddress: {
      firstName: deal?.properties?.billing_first_name,
      lastName: deal?.properties?.billing_last_name,
      company: '',
      streetInfo: deal?.properties?.billing_street_address,
      additionalStreetInfo: '',
      city: deal?.properties?.billing_city,
      zip: deal?.properties?.billing_postal_code,
      country: deal?.properties?.billing_country,
      state: deal?.properties?.shipping_state_region,
      email: deal?.properties?.billing_email,
      phone: deal?.properties?.billing_phone,
    },
    bcOrderId: deal?.properties?.big_commerce_order_id,
  }
}

export { hubspotDealToPlusOrder }
