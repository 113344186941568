import { TDealStage, TPlusOrderStatus } from './types'

export const HUBSPOT_API_ENDPOINTS = {
  OWNERS: '/crm/v3/owners',
  COMPANIES: '/crm/v3/objects/companies',
  CONTACTS: '/crm/v3/objects/contacts',
  DEALS: '/crm/v3/objects/deals',
  LINE_ITEMS_BATCH: '/crm/v3/objects/line_items/batch/read',
}

export const HUBSPOT_URL_BASE = process.env.HUBSPOT_URL_BASE
export const MARKETING_FORM_URL = process.env.MARKETING_FORM_URL

export const HUBSPOT_FORM_PRIVATE_APP_TOKEN =
  process.env.HUBSPOT_FORM_PRIVATE_APP_TOKEN

export const SIGNUP_FORM_ID = process.env.SIGNUP_FORM_ID
export const EDIT_ACCOUNT_DETAILS_FORM_ID =
  '0c4cdca3-052b-468e-b60e-9a5192593521'

export const HUBSPOT_AUTH_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${HUBSPOT_FORM_PRIVATE_APP_TOKEN}`,
  },
}

export const COMPANY_PROPERTIES = [
  'name',
  'hubspot_owner_id',
  'company_owner_picture',
]

export const DEALS_PROPERTIES = [
  'bypass_uop',
  'dealstage',
  'dealname',
  'closedate',
  'pipeline',
  'amount',
  'hubspot_owner_id',
  'createdate',
  'closedwondate',
  'closedlostdate',
  'hs_num_of_associated_line_items',
  'artwork_approved',
  'dealtype',
  'deal_currency_code',
  'cin7_so',
  'xero_invoice_number',
  'shipping_country',
  'shipping_city',
  'shipping_state_region',
  'shipping_postal_code',
  'shipping_street_address',
  'shipping_first_name',
  'shipping_last_name',
  'shipping_phone',
  'shipping_email',

  'billing_country',
  'billing_city',
  'billing_state_region',
  'billing_postal_code',
  'billing_street_address',
  'billing_first_name',
  'billing_last_name',
  'billing_phone',
  'billing_email',

  'carrier',
  'estimated_arrival',
  'tracking_number',
  'big_commerce_order_id',
]

export const LINE_ITEM_PROPS = [
  'createdate',
  'description',
  'hs_lastmodifieddate',
  'hs_object_id',
  'hs_sku',
  'hs_url',
  'hs_images',
  'quantity',
  'price',
  'hs_product_id',
  'name',
  'artwork_urls',
  'hs_cost_of_goods_sold',
  'amount',
]

export const QUOTE_PROPS = [
  'hs_createdate',
  'hs_expiration_date',
  'hs_quote_amount',
  'hs_quote_number',
  'hs_status',
  'hs_terms',
  'hs_title',
  'hubspot_owner_id',
]

export const PLUS_FREIGHT_SKUS = ['DDP', 'DDU', 'EXW']

export enum newPlusSalesPipelineStages {
  INDICATIVE_QUOTE_SENT = 'qualifiedtobuy',
  INVOICE_SENT = 'presentationscheduled',
  PENDING = '112456970',
  IN_PRODUCTION = '112456971',
  READY_TO_SHIP = '147787187',
  SHIPPED = '112456972',
  DELIVERED = '147810382',
  COMPLETE = '112456973',
}

export enum graduationFunnelPipelineStages {
  INDICATIVE_QUOTE_SENT = '50098354',
  INVOICE_SENT = '50098355',
  PENDING = '112450197',
  IN_PRODUCTION = '112450198',
  READY_TO_SHIP = '147798713',
  SHIPPED = '112450199',
  DELIVERED = '147827349',
  COMPLETE = '112450200',
}

export enum plusRepatSalesPipelineStages {
  INDICATIVE_QUOTE_SENT = '27052927',
  INVOICE_SENT = '27064343',
  PENDING = '112480623',
  IN_PRODUCTION = '112480624',
  READY_TO_SHIP = '147814047',
  SHIPPED = '112480625',
  DELIVERED = '147814057',
  COMPLETE = '112480626',
}

// Since we are interested in deals from three different pipleines, we have three stage IDs for every stage.
export const HUBSPOT_DEAL_STAGES: Record<
  Extract<TPlusOrderStatus, 'pending' | 'production' | 'shipped' | 'complete'>,
  TDealStage[]
> = {
  pending: [
    newPlusSalesPipelineStages.PENDING,
    graduationFunnelPipelineStages.PENDING,
    plusRepatSalesPipelineStages.PENDING,
  ],
  production: [
    newPlusSalesPipelineStages.IN_PRODUCTION,
    newPlusSalesPipelineStages.READY_TO_SHIP,
    graduationFunnelPipelineStages.IN_PRODUCTION,
    graduationFunnelPipelineStages.READY_TO_SHIP,
    plusRepatSalesPipelineStages.IN_PRODUCTION,
    plusRepatSalesPipelineStages.READY_TO_SHIP,
  ],
  shipped: [
    newPlusSalesPipelineStages.SHIPPED,
    plusRepatSalesPipelineStages.SHIPPED,
    graduationFunnelPipelineStages.SHIPPED,
  ],
  complete: [
    newPlusSalesPipelineStages.DELIVERED,
    newPlusSalesPipelineStages.COMPLETE,
    graduationFunnelPipelineStages.DELIVERED,
    graduationFunnelPipelineStages.COMPLETE,
    plusRepatSalesPipelineStages.DELIVERED,
    plusRepatSalesPipelineStages.COMPLETE,
  ],
}

// Deal stages that define that order is in a quote state
export const DRAFT_ORDER_DEAL_STAGES: TDealStage[] = [
  newPlusSalesPipelineStages.INVOICE_SENT,
  graduationFunnelPipelineStages.INVOICE_SENT,
  plusRepatSalesPipelineStages.INVOICE_SENT,
]
