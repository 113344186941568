'use client'
import { useEffect, useState } from 'react'
import { ISharedPageData } from '@utils/contentful/getSharedPageData/getSharedPageData'
import { useGtmInitialise } from '@framework/common/use-gtm-initialise'
import { PrimaryMenu } from './PrimaryMenu/PrimaryMenu'
import { useDevice } from '@utils/responsiveness/useDevice'
import { MobileNavbar } from './MobileNavbar/MobileNavbar'
import { NavbarDataProvider } from './NavbarDataProvider'
import { MobileShippingDestinationPopup } from './ShippingDestination/MobileShippingDestinationPopup'
import { LocationMismatchPopup } from './LocationMismatchPopup'
import { AnnouncementBanner } from 'app/(main)/_components/Navbar/AnnouncementBanner'

export const PRIMARY_MENU_ID = 'primaryMenu'

export function Navbar({
  sharedPageData,
}: {
  sharedPageData: ISharedPageData
}) {
  useGtmInitialise({ excludeUrl: 'order-confirmation' })

  const { isDesktop, isTablet, isMobile } = useDevice({
    mobileMediaQuery: 480,
    tabletMediaQuery: 1169,
  })
  const [isDisableDocumentScroll, setIsDisableDocumentScroll] = useState(false)
  const [searchIsActive, setSearchIsActive] = useState(false)

  // For Shipping Location Toggle
  const [shippingDestinationIsOpen, setShippingDestinationIsOpen] =
    useState(false)

  useEffect(() => {
    if (!isDisableDocumentScroll) {
      document.body.classList.remove('!overflow-hidden')
    }
  }, [isDisableDocumentScroll])

  useEffect(() => {
    if (isDesktop) {
      setIsDisableDocumentScroll(false)
    }
    if (isTablet || isMobile) {
      setIsDisableDocumentScroll(true)
    }
  }, [isDesktop, isTablet, isMobile])

  return (
    <NavbarDataProvider
      value={{
        primaryNavbarItems: sharedPageData?.navbarPrimaryMenuItems,
        socialMediaIcons: sharedPageData?.navbarSocialMediaIcons,
        searchIsActive,
        setSearchIsActive,
        shippingDestinationIsOpen,
        setShippingDestinationIsOpen,
      }}
    >
      {
        <>
          {sharedPageData?.announcementBanner && (
            <AnnouncementBanner
              {...sharedPageData?.announcementBanner}
              trackingKey="Sitewide Announcement Bar"
            />
          )}

          <div
            id={PRIMARY_MENU_ID}
            className={`bg-core-white top-0 sticky w-full z-[9999] transition-all ease-linear duration-300 `}
          >
            <div className="content-container min-h-[72px]">
              {isDesktop && <PrimaryMenu />}

              {(isTablet || isMobile) && (
                <>
                  <MobileNavbar
                    mobileFooterLinks={
                      sharedPageData?.navbarMobileSidebarFooterLinks
                    }
                    navbarMobileTiles={sharedPageData?.navbarMobileTiles}
                    announcementBannerLinks={
                      sharedPageData?.announcementBanner?.secondaryLinks
                    }
                  />
                  <MobileShippingDestinationPopup />
                </>
              )}
              <LocationMismatchPopup />
            </div>
          </div>
        </>
      }
    </NavbarDataProvider>
  )
}
