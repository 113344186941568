import { AccountPopup } from '@components/common/UserNav/AccountPopup'
import { LoginSignup } from '@components/common/UserNav/LoginSignup'
import Link from '@components/common/NoPrefetchLink'
import { Button } from '@noissue-ui-kit/button'
import { useSession } from 'next-auth/react'

export function AccountNav() {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  if (loading) {
    return <div className="flex min-w-[157px]"></div>
  }

  return (
    <div className="flex-shrink-0 my-auto min-w-[157px]">
      {session ? (
        <div className="flex items-center justify-start">
          <a href="/admin/orders">
            <Button variant="primary" colour="acai" size="xs" corners="rounded">
              My Orders
            </Button>
          </a>
          <AccountPopup />
        </div>
      ) : (
        <>
          <LoginSignup className="mr-[16px]" />
        </>
      )}
    </div>
  )
}
