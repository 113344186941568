import React from 'react'
import { useSession } from 'next-auth/react'

export function CustomisedUserGreeting() {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  if (!loading) {
    const { given_name: firstName, family_name: familyName } = session

    return (
      // @ts-ignore
      <p className="font-semibold text-aca text-xl mt-10 mb-8">
        Hi {firstName}
        {familyName ? ` ${familyName}!` : '!'}
      </p>
    )
  } else {
    return null
  }
}
