import { AUTH_EVENTS_GTM_QUERY } from './constants'

const getParameterByName = (name, url) => {
  if (typeof window !== `undefined`) {
    url = window.location.href
  } else {
    return null
  }

  name = name.replace(/[[\]]/g, '\\$&')

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)

  if (!results) {
    return null
  }

  if (!results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default getParameterByName

export const getPrice = (price) => parseFloat(price).toFixed(2)

export const getCurrency = (currency, type) => {
  if (type === 'symbol') {
    if (currency.split(',')[1]) {
      return currency.split(',')[1].slice(-1)
    }
    return '$'
  } else if (type === 'code') {
    return currency.split(',')[0].toLowerCase()
  }

  return currency
}

export interface IObject {
  [key: string]: any
}

/**
 * Because the old code added unnecessary file urls into order customizations which start with '/data/var/www/noissue.co',
 * thus mark as invalid prefix.
 */
export const invalidFileUrlPrefix = '/data/var/www/noissue.co'

const toBeRemovedFromKeys = ['logos', 'logoUrls', 'filesUrls', 'customFontUrls']

export const filterOutInvalidFileUrls = <T>(arr: T[]): T[] => {
  return arr.filter((item: any) => {
    if (typeof item === 'string') {
      return !item.startsWith(invalidFileUrlPrefix)
    }
    return item
  })
}

/**
 * The old code insert unnecessary file urls start with `invalidFileUrlPrefix`,
 * to make reorder from old orders work fine, need to remove those kind of unnecessary upload file urls
 * @param obj any object which includes properties ['logos', 'logoUrls', 'filesUrls', 'customFontUrls']
 */
export const removeInvalidFileUrls = (obj: IObject) => {
  if (!obj || typeof obj !== 'object') {
    return
  }
  for (const key of toBeRemovedFromKeys) {
    if (obj[key] instanceof Array) {
      obj[key] = filterOutInvalidFileUrls(obj[key])
    }
  }
}

type TScrollSmooth = {
  direction?: 'vertical' | 'horizontal'
  duration?: number
  offset?: number
}

/**
 * Scroll smoothly to element.
 * TODO: I did not test horizontal situation,
 * modify the code if necessary to ensure that horizontal scrolling works properly.
 * @param element Element to be scrolled
 * @param options control options
 */
export const scrollToElementSmoothly = (
  element: HTMLElement,
  { direction = 'vertical', duration = 1000, offset = 0 }: TScrollSmooth = {
    direction: 'vertical',
    duration: 1000,
    offset: 0,
  }
) => {
  let startingPosition = window.scrollY
  if (direction === 'horizontal') {
    startingPosition = window.scrollX
  }

  let start = 0

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) {
      start = timestamp
    }
    let elementStartPosition = element.offsetTop
    if (direction === 'horizontal') {
      elementStartPosition = element.offsetLeft
    }

    const diff = elementStartPosition - startingPosition - offset
    const elapsed = timestamp - start
    const percent = Math.min(elapsed / duration, 1)
    window.scrollTo(0, startingPosition + diff * percent)
    if (elapsed < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

const loadScript = (scriptSrc: string, scriptId: string) => {
  if (!scriptSrc) {
    throw new Error('Script src is required!')
  }
  if (!scriptId) {
    throw new Error('Script id is required')
  }
  const script = document.createElement('script')
  script.id = scriptId
  script.type = 'text/javascript'
  script.src = scriptSrc
  document.head.appendChild(script)
}

const checkScriptLoaded = (globalVar: string, onLoaded: () => void) => {
  const timer = setInterval(() => {
    if (window[globalVar]) {
      if (typeof onLoaded === 'function') {
        onLoaded()
      }
      clearInterval(timer)
    }
  }, 100)
}

let isProcessed = false
export const loadExternalScript = ({
  scriptSrc,
  scriptId,
  globalVar,
  onLoaded,
}: {
  scriptSrc: string
  scriptId: string
  globalVar: string
  onLoaded: () => void
}) => {
  if (!isProcessed) {
    isProcessed = true
    loadScript(scriptSrc, scriptId)
    checkScriptLoaded(globalVar, onLoaded)
  }
}

export const isValidHexColor = (color: string) => {
  return /^#[0-9A-F]{3,6}$/i.test(color?.trim())
}

export function normalizeUrl(slug: string) {
  // Normalise slug
  if (slug && !slug.endsWith('/')) {
    slug = slug + '/'
  }
  return slug
}

export function removeHashTagAndQuery(slug: string) {
  if (!slug) {
    return slug
  }

  const removedHashTagAndQuery = slug.split('#')[0]?.split('?')[0]
  return removedHashTagAndQuery
}

export const generateRandomStr = () => {
  return (Math.random() + 1).toString(36).substring(7)
}

export const appendQueryParameter = ({
  url,
  parameter,
  value,
}: {
  url: string
  parameter: string
  value: string
}): string => {
  if (!url) {
    return null
  }
  if (!parameter || !value) {
    return url
  }

  if (url.includes('?')) {
    if (url.slice(-1) !== '?') {
      return `${url}&${parameter}=${value}`
    }
    return `${url}${parameter}=${value}`
  }

  return `${url}?${parameter}=${value}`
}

export function getRedirectAfterLoginUrl(
  originalUrl: string,
  skipProfile = false
) {
  if (skipProfile) {
    return appendQueryParameter({
      url: originalUrl,
      parameter: AUTH_EVENTS_GTM_QUERY,
      value: 'true',
    })
  }

  return `/sign-up/profile/?original_url=${
    encodeURIComponent(
      appendQueryParameter({
        url: originalUrl,
        parameter: AUTH_EVENTS_GTM_QUERY,
        value: 'true',
      })
    ) ?? '/'
  }`
}

export const camelToSnakeCase = (str) => {
  return str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '_' : '') + $.toLowerCase()
  )
}

export const camelToKebabCase = (str) => {
  return str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  )
}

export const kebabToTitleCase = (inputString) => {
  return (
    inputString
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') || ''
  )
}

export const kebabToSnakeCase = (inputString) => {
  return inputString.replaceAll('-', '_') || ''
}

export const camelCaseToTitleCase = (str) => {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const isoDateToReadableDate = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}
export const isoDateToTimestamp = (isoDate): number => {
  const date = new Date(isoDate)
  return date.getTime()
}

export const mmToInches = (mm) => {
  const conversionCoefficient = 25.4
  return Number((mm / conversionCoefficient).toFixed(2))
}

// Strip param and rewrite history to prevent back button / page reload resubmission
export const removeQueryParams = ({
  path,
  query,
  paramNames,
}: {
  path: string
  query: string
  paramNames: string[]
}) => {
  const url = new URL(path, window.location.origin)
  const params = new URLSearchParams(query)

  paramNames.forEach((param) => params.delete(param))

  window.history.replaceState(
    {},
    undefined,
    `${url.pathname}?${params.toString()}`
  )
}
