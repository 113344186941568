import { loadExternalScript } from '@utils/utilities'
import { useLocationMismatchCheck } from 'app/_hooks/useLocationMismatchCheck'
import parentLogger from '@utils/logger'

const logger = parentLogger.child({
  name: 'useSupportWidgetConfig',
})

declare global {
  interface Window {
    zE: any
  }
}

const ZENDESK_SCRIPT_ID = 'ze-snippet'
const ZENDESK_SCRIPT_SRC =
  'https://static.zdassets.com/ekr/snippet.js?key=44b671b2-db84-4307-b609-25b58e584470'
const ZENDESK_GLOBAL_VAR = 'zE'

const STORE_REGION_FIELD_ID = 44066541256985

enum SUPPORT_TICKET_FORMS {
  GENERAL = 43120433449881,
  NEW_ORDER = 43060832031769,
  EXISTING_ORDER = 43120355323801,
}

export function useSupportWidgetConfig() {
  const { currentLocation, correctLocation } = useLocationMismatchCheck()

  const injectSupportWidgetStyles = () => {
    const styleElement = document.createElement('style')

    styleElement.textContent = `
            label[data-fieldid="key:${STORE_REGION_FIELD_ID}"] {
                display: none !important;
            }
            input[name="key:${STORE_REGION_FIELD_ID}"] {
                display: none !important;
            }
        `

    const observer = new MutationObserver((_, obs) => {
      const widgetFrame: HTMLIFrameElement | null = document.getElementById(
        'webWidget'
      ) as HTMLIFrameElement

      if (widgetFrame) {
        const iframeDocument =
          widgetFrame.contentDocument || widgetFrame.contentWindow.document
        iframeDocument.head.appendChild(styleElement)

        // Disconnect once we've found and updated the widget
        obs.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }

  const prefillStoreRegionField = () => {
    logger.debug('Prefilling Store Region Field')

    const ticketFormsConfig = [
      SUPPORT_TICKET_FORMS.GENERAL,
      SUPPORT_TICKET_FORMS.EXISTING_ORDER,
      SUPPORT_TICKET_FORMS.NEW_ORDER,
    ].map((formId) => ({
      id: formId,
      fields: [
        {
          id: STORE_REGION_FIELD_ID,
          prefill: {
            '*': `${currentLocation?.key}`,
          },
        },
      ],
    }))

    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          ticketForms: ticketFormsConfig,
        },
      },
    })
  }

  const loadSupportWidgetScript = () => {
    loadExternalScript({
      scriptSrc: ZENDESK_SCRIPT_SRC,
      scriptId: ZENDESK_SCRIPT_ID,
      globalVar: ZENDESK_GLOBAL_VAR,

      onLoaded: () => {
        logger.debug('Support Widget Script Loaded')

        onSupportWidgetOpen(() => {
          prefillStoreRegionField()
          injectSupportWidgetStyles()
        })
      },
    })
  }

  const toggleSupportWidget = () => {
    window.zE && window.zE('webWidget', 'toggle')
  }

  const onSupportWidgetOpen = (callback: () => void) => {
    window.zE('webWidget:on', 'open', callback)
  }

  return {
    loadSupportWidgetScript,
    toggleSupportWidget,
  }
}
