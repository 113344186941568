import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { CartSidebarView } from '@components/cart/CartSidebarView/CartSidebarView'
import { Footer } from '@components/common'
import { LoadingDots, Sidebar, useUI } from '@components/ui'
import { CommerceProvider } from '@framework'
import { useAcceptCookies } from '@utils/hooks/useAcceptCookies'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ISharedPageData } from '@utils/contentful/getSharedPageData/getSharedPageData'
import { ICartSidebar } from 'types/contentful/cartSidebar'
import { Entry } from 'contentful'
import { Navbar } from '../Navbar/Navbar'
import { useSession } from 'next-auth/react'
import { AUTH_EVENTS_GTM_QUERY } from '@utils/constants'
import isEmpty from 'lodash/isEmpty'
import { dataLayerPushWithTimestamp } from '@utils/gtm'
import s3 from 'aws-sdk/clients/s3'
import useCart from '@components/orders/hooks/useCart'
import Cookie from 'js-cookie'
import { useNextQueryParam } from '@utils/hooks/useNextQueryParam.hook'
import { removeQueryParams } from '@utils/utilities'
import OneTap from 'app/(auth)/_components/OneTap/OneTap'

export const zendeskHiddenList = [
  '/blog/[[...slug]]',
  '/community/[[...slug]]',
  '/sustainability/[[...slug]]',
  '/custom-packaging/designer/community/[...slug]',
  '/custom-packaging/[slug]',
  '/upload/[slug]',
  '/quote/[slug]',
]

const Loading = () => (
  <div className="flex items-center justify-center p-3 text-center w-80 h-80">
    <LoadingDots />
  </div>
)

const CookiesPopup = dynamic(
  () => import('@components/common/CookiesPopup/index'),
  {
    loading: Loading,
  }
)

interface Props {
  pageProps: {
    commerceFeatures: Record<string, boolean>
    cartSidebarData?: Entry<ICartSidebar>
    cartUpsellData?: any
    sharedPageData: ISharedPageData
    hideLayoutFooter?: boolean
  }
}

const Layout: FC<PropsWithChildren<Props>> = ({
  children,
  pageProps: { commerceFeatures, ...pageProps },
}) => {
  const { displaySidebar, closeSidebar, openSidebar } = useUI()

  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { locale = 'en-US', pathname, query, asPath } = useRouter()
  const { cartSidebarData, cartUpsellData, sharedPageData, hideLayoutFooter } =
    pageProps

  const [upsellSidebar, setUpsellSidebar] = useState(false)
  useState(false)
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  // Capturing GTM events after redirect on successful authentication.
  useEffect(() => {
    if (
      !loading &&
      !isEmpty(session) &&
      Boolean(query?.[AUTH_EVENTS_GTM_QUERY])
    ) {
      const eventName = session.isNewCustomer ? 'registration' : 'login'

      dataLayerPushWithTimestamp({
        event: eventName,
        provider: session.provider,
        eventStatus: 'complete',
        customerEmail: session.email,
      })
    }
  }, [session, loading, query])

  useEffect(() => {
    if (query?.openSidebar === 'true') {
      openSidebar()
    }
  }, [query])

  // Add line items to the cart if cartHash query parameter is present.
  const { createCart } = useCart()
  const [loadingSharedCart, setLoadingSharedCart] = useState<boolean>(false)

  useEffect(() => {
    const getLineItems = async (cartHash) => {
      try {
        const items = await (
          await fetch(
            `/api/bigcommerce/adminCart/getSharedCartItems?configId=${cartHash}`
          )
        ).json()

        if (items) {
          const parsedItems = items.map((x) => ({
            quantity: +x.quantity,
            variant_id: +x.variantId,
            product_id: +x.productId,
            option_selections: x?.options?.map((option) => ({
              option_id: +option.nameId,
              option_value: option.valueId
                ? String(option.valueId)
                : JSON.stringify(option?.value),
            })),
          }))

          const cartResponse = await createCart({
            lineItems: parsedItems,
          })

          Cookie.set('bc_cartId', cartResponse?.cart?.id)
          openSidebar()

          removeQueryParams({
            path: asPath,
            query: query as unknown as string,
            paramNames: ['cartHash', 'slug'],
          })
        }
      } finally {
        setLoadingSharedCart(false)
      }
    }

    if (query?.cartHash) {
      getLineItems(query?.cartHash)
    }
  }, [query?.cartHash])

  // Using this hacky hook to get query params before router.query will be ready
  // and start the loading state in case if cartHash param is present.
  // Otherwise, in case when we rely purely on router.query,
  // there will be a significant delay before loader becomes visible, which may confuse users.
  const cartHashParam = useNextQueryParam('cartHash')

  useEffect(() => {
    if (cartHashParam) {
      setLoadingSharedCart(true)
    }
  }, [cartHashParam])

  return (
    <CommerceProvider locale={locale}>
      <div className="h-full mx-auto transition-colors duration-150 bg-black-20">
        {loadingSharedCart && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-white opacity-90 z-[10000000] flex">
            <img
              className="w-40 h-40 m-auto rounded-full justify-center items-center"
              src={'/icon-uploading-new-brand.gif'}
              alt="uploading"
            />
          </div>
        )}

        <Navbar sharedPageData={sharedPageData} />
        <main className="fit 2lg:pt-[50px] content-container">{children}</main>

        {!hideLayoutFooter && (
          <Footer
            footerColumns={sharedPageData?.footerColumns}
            bannerContent={sharedPageData?.footerBannerQRcode}
            badgeImage={sharedPageData?.footerBadge}
            footerSignupForm={sharedPageData?.footerSignupForm}
          />
        )}

        <Sidebar
          open={displaySidebar}
          upsellSidebar={upsellSidebar}
          onClose={closeSidebar}
        >
          <CartSidebarView cartSidebarData={cartSidebarData} />
        </Sidebar>

        <CookiesPopup />
      </div>
      {process.env.NEXT_PUBLIC_ONE_TAP_ENABLED === 'true' && <OneTap />}
    </CommerceProvider>
  )
}

export default Layout
