import { SlugOrLink } from '@components/common/SlugOrLink'
import { TLink } from '../Navbar.types'
import { SocialMediaIcons } from '../SecondaryMenu/SocialMediaIcons/SocialMediaIcons'
import { ShippingDestinationToggle } from '../ShippingDestination/ShippingDestinationPopup'
import { useShippingDestinationPopUp } from '../NavbarDataProvider'
import { codeToShippingLocation } from '../ShippingDestination/utils'
import router from 'next/router'

export const MobileFooter = ({
  mobileFooterLinks,
}: {
  mobileFooterLinks: TLink[]
}) => {
  const { shippingDestinationIsOpen, setShippingDestinationIsOpen } =
    useShippingDestinationPopUp()
  const currentShippintDestination = codeToShippingLocation(
    router?.query?.countryCode || null
  )

  return (
    <div className="flex flex-col items-start gap-[32px]">
      <ShippingDestinationToggle
        selectedLocation={currentShippintDestination}
        open={shippingDestinationIsOpen}
        onClick={(event) => {
          event?.stopPropagation()
          event?.preventDefault()
          setShippingDestinationIsOpen(!shippingDestinationIsOpen)
        }}
      />

      <SocialMediaIcons />
    </div>
  )
}
