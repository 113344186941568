'use client'

import { COOKIE_CONTAINER, COOKIE_USER_COUNTRY } from '@utils/constants'
import Cookie from 'js-cookie'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { useSearchParams } from 'next/navigation'
import {
  codeToShippingLocation,
  countryCodeToShippingLocation,
} from 'app/(main)/_components/Navbar/ShippingDestination/utils'

export function useLocationMismatchCheck() {
  const userCountry = Cookie.get(COOKIE_USER_COUNTRY)?.toUpperCase()
  const userContainer = Cookie.get(COOKIE_CONTAINER)

  const query = useSearchParams()
  const queryCountryCode = query?.get('siteRegion')

  const userRegion = codeToShippingLocation(
    countryCodeToShippingLocation(userCountry)
  )
  const currentRegion = codeToShippingLocation(
    queryCountryCode || userContainer || NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY
  )

  const locationDoesMismatch = userRegion?.key !== currentRegion?.key

  return {
    locationDoesMismatch,
    correctLocation: userRegion,
    currentLocation: currentRegion,
  }
}
