import { addCartMetafield } from 'app/(main)/_components/Cart/CartSidebarView/cartMetafields.utils'
import Cookie from 'js-cookie'
import isEmpty from 'lodash/isEmpty'

const parseResponse = async (response) => {
  if (response.status !== 200 && response.status !== 201) {
    const errorMessage = await response.text()
    throw errorMessage
  }

  const parsedResponse = await response.json()

  if (parsedResponse) {
    if (isEmpty(parsedResponse?.cart)) {
      throw new Error(
        parsedResponse?.title ||
          'Something went wrong. Please tyr later or contact support team.'
      )
    }
    return parsedResponse
  }
}

const useCart = () => {
  const getCartId = () => Cookie.get('bc_cartId')

  const deleteCart = async (cartId: string) =>
    await fetch(`/api/bigcommerce/adminCart/${cartId}/`, { method: 'DELETE' })

  const createCart = async ({
    originalOrderId = '',
    lineItems = [],
    metafields = [],
    currencyCodeOverride,
  }: {
    originalOrderId?: string
    lineItems?: any[]
    metafields?: any[]
    currencyCodeOverride?: string
  } = {}) => {
    try {
      const response = await fetch('/api/bigcommerce/adminCart/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...(originalOrderId ? { editOrderId: originalOrderId } : {}),
          lineItems,
          metafields,
          currencyCodeOverride,
        }),
      })

      const { cart } = await parseResponse(response)
      if (!cart?.id) {
        throw new Error(`Cart creation failed`)
      }

      if (!!metafields.length) {
        for (const metafieldPayload of metafields) {
          await addCartMetafield({ cartId: cart.id, metafieldPayload })
        }
      }

      return { cart }
    } catch (err) {
      return { error: err }
    }
  }

  const setCart = async (id = '') => {
    if (!id) {
      const { cart } = await createCart()
      Cookie.set('bc_cartId', cart.id)
    } else {
      Cookie.set('bc_cartId', id)
    }
  }

  const addLineItems = async ({
    originalOrderId = '',
    lineItems = [],
  }: {
    originalOrderId?: string
    lineItems?: any[]
  } = {}) => {
    try {
      const response = await fetch('/api/bigcommerce/adminCart/', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...(originalOrderId ? { editOrderId: originalOrderId } : {}),
          cartId: getCartId(),
          lineItems,
        }),
      })

      return await parseResponse(response)
    } catch (err) {
      return { error: err }
    }
  }

  return { createCart, deleteCart, getCartId, setCart, addLineItems }
}

export default useCart
