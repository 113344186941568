import { useEffect, useState } from 'react'
import { PRIMARY_MENU_ID } from './Navbar'
import { SECONDARY_MENU_ID } from './SecondaryMenu/SecondaryMenu'
import { ANNOUNCEMENT_BAR_ID } from 'app/(main)/_components/Navbar/AnnouncementBanner'

const getHeightOfPrimaryMenu = () =>
  document?.getElementById(PRIMARY_MENU_ID)?.getBoundingClientRect().height || 0

const getHeightOfSecondayMenu = () =>
  document?.getElementById(SECONDARY_MENU_ID)?.getBoundingClientRect().height ||
  0

const getAnnouncementBarHeight = () =>
  document?.getElementById(ANNOUNCEMENT_BAR_ID)?.getBoundingClientRect()
    .height || 0

export function useNavbarHeightPx() {
  const [primaryMenuHeightPx, setPrimaryMenuHeightPx] = useState(0)
  const [announcementBarHeightPx, setAnnouncementBarHeightPx] = useState(0)
  const [secondaryMenuHeightPx, setSecondaryMenuHeightPx] = useState(0)

  useEffect(() => {
    const getNavbarHeight = () => {
      setPrimaryMenuHeightPx(getHeightOfPrimaryMenu())
      setAnnouncementBarHeightPx(getAnnouncementBarHeight())
      setSecondaryMenuHeightPx(getHeightOfSecondayMenu())
    }

    window.addEventListener('scroll', getNavbarHeight)
    return () => {
      window.removeEventListener('scroll', getNavbarHeight)
    }
  }, [])

  return { primaryMenuHeightPx, announcementBarHeightPx, secondaryMenuHeightPx }
}
