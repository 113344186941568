import React from 'react'
import useCart from '@commerce/cart/use-cart'
import type { LineItem } from '@framework/types'

import { useUI } from '@components/ui'
import { CartIcon } from 'app/(main)/_components/Cart/CartIcon'

export function CartButton() {
  const { toggleSidebar } = useUI()
  const { data } = useCart()

  const countItem = (count: number, item: LineItem) => count + item.quantity

  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0

  return (
    <button
      className="relative flex items-center pr-3 mr-0 transition duration-100 ease-in-out rounded-sm outline-none cursor-pointer focus:ring-2 focus:ring-core-rose focus:ring-offset-4 active:ring-0 h-[35px] my-auto"
      onClick={toggleSidebar}
      tabIndex={0}
    >
      <CartIcon cartCount={itemsCount} />
    </button>
  )
}
