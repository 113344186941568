import capitalize from 'lodash/capitalize'

interface ICartItemOption {
  name: string
  value: string
  url?: string
}

const itemPropsInPriority: string[] = [
  'quantity',
  'size',
  'artwork',
  'shape',
  'colors',
  'color',
  'print option',
  'thickness (gsm)',
  'type',
  'material',
  'edge type',
  'rolls',
]

export const ARTWORK_PROP_NAME = 'Artwork'
export const ARTWORK_PROP_PLACEHOLDER = `You're sending this later`

const extractPropFromItemOptions = (propertyName, item): ICartItemOption => {
  const itemOptions = item?.options
  const property = itemOptions?.find((option) => {
    return option?.name?.toLowerCase() === propertyName?.toLowerCase()
  })

  if (property?.value) {
    return {
      name: capitalize(propertyName),
      value: property?.value,
    }
  }
  return null
}

const extractPropFromCustomizationObj = (
  propertyName,
  customizations
): ICartItemOption => {
  if (customizations?.[propertyName]) {
    return {
      name: capitalize(propertyName),
      value: customizations?.[propertyName],
    }
  }
  return null
}

const getArtworkOption = (customizations) => {
  let fileName = (
    customizations?.logoUrls?.[0] ||
    customizations?.filesUrls?.[0] ||
    customizations?.fullpath
  )
    ?.split('/')
    ?.slice(-1)[0]
    ?.split('.')

  fileName?.splice(-2, 1)
  fileName = fileName?.join('.')

  const totalUploadedFiles =
    customizations?.logoUrls?.length || customizations?.filesUrls?.length

  const showExtraFiles = totalUploadedFiles > 1

  return {
    name: ARTWORK_PROP_NAME,
    value: fileName
      ? fileName +
        (showExtraFiles ? ` (and ${totalUploadedFiles - 1} other files)` : '')
      : ARTWORK_PROP_PLACEHOLDER,
  }
}

const getPropertiesToShow = ({
  item,
  customizations,
  optionsSchema,
  isReorder = false,
}): ICartItemOption[] => {
  const propsFromContentfulSchema =
    optionsSchema[item?.categorySlug]
      ?.map((option) => option?.name)
      ?.filter(Boolean) || null

  const itemPropsToDisplay = propsFromContentfulSchema || itemPropsInPriority

  const cartItemInfoOptions = itemPropsToDisplay?.map((propertyName) => {
    if (propertyName === 'artwork' && customizations) {
      return isReorder && customizations?.productionFileUrl
        ? {
            name: ARTWORK_PROP_NAME,
            value: 'Production File',
            url: customizations?.productionFileUrl,
          }
        : getArtworkOption(customizations)
    }
    return (
      extractPropFromItemOptions(propertyName, item) ||
      extractPropFromCustomizationObj(propertyName, customizations)
    )
  })

  return cartItemInfoOptions
    ?.filter((option) => Boolean(option))
    ?.slice(0, 5)
    ?.sort((a) => (a.name === ARTWORK_PROP_NAME ? 1 : -1))
}

export { getPropertiesToShow }
