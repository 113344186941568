import Cookie from 'js-cookie'
import enc from 'crypto-js/enc-hex'
import sha256 from 'crypto-js/sha256'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import * as gtm from '@utils/gtm'
import { v4 as uuid } from 'uuid'
import { useRouter } from 'next/router'
import useCustomer from '@framework/customer/use-customer'
import { useUI } from '@components/ui'
import { useEffect, useState } from 'react'
import {
  COOKIE_DATALAYER,
  CUSTOMER_TYPE_IN_COOKIE,
  USER_HASH_ID_IN_COOKIE,
} from '@utils/constants'
import { useSession } from 'next-auth/react'

const INITIALIZATION_EVENT_ID = 'dataLayer-initialized'

export function useGtmInitialise({
  excludeUrl,
  customerTypeOverriden,
}: {
  excludeUrl?: string
  customerTypeOverriden?: string
}) {
  const router = useRouter()
  const { data: customer, isLoading: isCheckingCustomer } = useCustomer()
  const { preparingCoreSettings, finishedCoreSettings } = useUI()
  const { data: session } = useSession()

  useEffect(() => {
    const pageUrl = router.asPath

    if (excludeUrl && new RegExp(excludeUrl).test(pageUrl)) {
      return
    }

    preparingCoreSettings()
    if (isCheckingCustomer || !router.isReady) {
      return
    }

    // Get usrHashId from Cookie first
    const usrHashId = Cookie.get(USER_HASH_ID_IN_COOKIE)
    const encryptedUserEmail = enc.stringify(sha256(customer?.email))
    // If no usrHashId define, if customer define: set customerEmailHash else set a random uuid
    // If has usrHashId but user login and it doesn't update yet, update it
    if (customer && usrHashId !== encryptedUserEmail) {
      // usrHashId can be undefined or some random uuid first
      // Reset cookie usrHashId to current user logged in
      Cookie.set(USER_HASH_ID_IN_COOKIE, encryptedUserEmail)
    } else if (!usrHashId) {
      Cookie.set(USER_HASH_ID_IN_COOKIE, uuid())
    }

    // Same for customer type, otherwise, when existing customer log out, it will become new which is not nice
    const customerTypeCookie = Cookie.get(CUSTOMER_TYPE_IN_COOKIE)
    const customerType = customer?.customerType || 'new'
    if (
      customer &&
      customerTypeCookie !== 'existing' && // We don't reset from existing to new but will update new to existing
      customerType === 'existing'
    ) {
      Cookie.set(CUSTOMER_TYPE_IN_COOKIE, customerType)
    } else if (!customerTypeCookie) {
      // Not in cookie yet, initialise it or else, just leave it untouch
      Cookie.set(CUSTOMER_TYPE_IN_COOKIE, customerType)
    }

    window?.dataLayer?.push({
      event: INITIALIZATION_EVENT_ID,
      userHashId: Cookie.get(USER_HASH_ID_IN_COOKIE),
      customerType:
        customerTypeOverriden || Cookie.get(CUSTOMER_TYPE_IN_COOKIE),
      customerGroup: customer?.customerGroup || '',
      customerIndustry: customer?.industryType || '',
      noissueProgram: customer?.noissueProgram || '',
      loggedIn: customer || session ? 'true' : 'false',
      siteRegion: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY,
    })

    gtm.pageview(pageUrl)

    if (customerTypeOverriden) {
      // New order placed. Need to refresh info
      Cookie.remove(COOKIE_DATALAYER)
    }
    finishedCoreSettings()
  }, [customer, router])
}

const containsInitializationEventId = (dataLayer = []) => {
  const eventsIds = dataLayer
    .map((pushedEvent) => pushedEvent.event)
    .filter(Boolean)

  return eventsIds.includes(INITIALIZATION_EVENT_ID)
}

export function useIsDataLayerReady() {
  const [dataLayerIsReady, setDataLayerIsReady] = useState(false)

  useEffect(() => {
    const dataLayerWasInitialized = containsInitializationEventId(
      window?.dataLayer
    )
    setDataLayerIsReady(dataLayerWasInitialized)
  })

  return dataLayerIsReady
}
